<template>
  <div>
    <ConnectWallet />
    <div class="z-0 font-sans" v-if="getToken == 'false'">
      <div
        class="bg-cover h-screen w-screen bg-center flex items-center justify-center"
        style="
          background-image: url('https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png');
        "
      >
        <div class="text-center">
          <span class="text-5xl font-bold sm:text-8xl text-white">
            Will Artificial Intelligence (AI) replace humans ?
          </span>
        </div>
      </div>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-center">
          <hr class="h-px my-4 border-blue-700 border" />

          <a
            class="text-xl font-medium xl:text-5xl hover:-translate-y-1 cursor-pointer text-shadow-white text-purple-900"
          >
            Will Artificial Intelligence (AI) replace humans ?
          </a>
          <hr class="h-px my-4 border-blue-700 border" />
        </div>

        <h3 class="text-xl font-medium xl:text-2xl text-purple-900">
          The question of whether artificial intelligence (AI) will replace
          humans is a complex and debated topic. While AI has made significant
          advancements in various domains and has the potential to automate
          certain tasks, it is unlikely to completely replace humans in the
          foreseeable future. Here are a few key points to consider:
        </h3>

        <div class="flex justify-center">
          <ul class="list-disc text-lg xl:text-xl mt-5">
            <li class="mt-2">
              Complementary Role: AI is often designed to augment human
              capabilities rather than replace them entirely. AI systems excel
              at processing large amounts of data, performing repetitive tasks,
              and making predictions based on patterns. Humans, on the other
              hand, possess creativity, critical thinking, emotional
              intelligence, and complex problem-solving skills that are
              currently beyond the reach of AI systems. The combination of human
              expertise and AI capabilities can lead to more efficient and
              effective outcomes.
            </li>
            <li class="mt-4">
              Limitations of AI: Despite its advancements, AI still has
              limitations. Current AI systems are highly specialized and
              task-specific, lacking the broader understanding and adaptability
              of human intelligence. They rely on data availability and may
              struggle with unstructured or novel situations. AI systems also
              lack consciousness, intuition, and common sense reasoning, which
              are inherent in human intelligence and crucial for many
              decision-making scenarios.
            </li>
            <li class="mt-4">
              Human-Centric Fields: Certain fields require human interaction,
              empathy, and intuition. Professions like counseling, therapy,
              social work, creative arts, and many aspects of customer service
              heavily rely on human-to-human connections, emotional
              understanding, and empathy. While AI can assist in these areas,
              the human element is often considered essential for successful
              outcomes.
            </li>
            <li class="mt-4">
              Ethical and Social Considerations: The widespread replacement of
              humans by AI raises ethical concerns and societal implications.
              Job displacement and economic disruption are valid concerns, as
              certain tasks and roles become automated. It is crucial to ensure
              that the impact of AI on employment is carefully managed, and
              efforts are made to reskill and adapt the workforce to new roles
              that leverage human strengths alongside AI capabilities.
            </li>

            <li class="mt-4">
              Collaboration and Partnership: The most promising approach is the
              collaboration between humans and AI. AI can handle routine and
              repetitive tasks, freeing up humans to focus on more complex and
              creative endeavors. By leveraging AI as a tool and harnessing its
              capabilities, humans can achieve higher productivity, solve more
              significant problems, and create new opportunities.
            </li>
          </ul>
        </div>

        <h3 class="text-lg font-bold xl:text-lg mt-5">
          It is important to note that AI technology is constantly evolving, and
          its future trajectory is uncertain. While some tasks may become
          automated, new opportunities and roles may also emerge. The key lies
          in embracing the potential of AI while also recognizing and nurturing
          the unique qualities and skills that humans bring to the table.
        </h3>
      </div>

      <News />
      <Footer />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import Footer from "@/components/Footer.vue";
import News from "@/components/News.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      news: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657420112053/AI_Replace_Human.png",
          title: "Will Artificial Intelligence (AI) replace humans ?",
          list: "1",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285657885683784/AI.png",
          title: "AI in agency industry",
          list: "2",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658401579018/Comparison.png",
          title: "Comparison between virtual influencer and human influencer ?",
          list: "3",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1121285658795847692/What_is_virtualKOL.png",
          title: "What is VirtualKOL ?",
          list: "4",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    News,
    Footer,
  },
  computed: mapGetters(["getToken"]),

  methods: {},
  created() {},
};
</script>
